import _isValid from "redux-form/es/isValid";
import _getFormValues from "redux-form/es/getFormValues";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createSelector } from 'reselect';
import { InputColor } from 'swag-client-common/interfaces/input';
import { sortPossibleDeliveries } from 'swag-client-common/utils/distributions/shipouts.utils';
import { checkIsApparelInBundle } from 'swag-client-common/utils/product.utils';
import { getBoxVariantSizeIndex } from 'swag-common/business-logic/custom-store-product/get-box-variant-size-name.logic';
import { hasProductSizes } from 'swag-common/business-logic/custom-store-product/has-product-sizes.logic';
import { isValidPFIShippingForm } from 'swag-common/business-logic/custom-store-shipout/is-valid-pfi-shipping-form';
import { totalPriceAfterPromocodeApplied } from 'swag-common/business-logic/payments/total-price-after-promocode-applied';
import isBox from 'swag-common/utils/custom-store-product/product-is-box.util';
import { isBundle } from 'swag-common/utils/custom-store-product/product-is-bundle.util';
import isProductFullDesign from 'swag-common/utils/custom-store-product/product-is-full-design.util';
import { getInHandDateFormatted } from 'swag-common/utils/order';
import { renameDeliveryOptions } from 'swag-common/utils/rename-delivery-options';
import { validateQuantitiesForProductsSOABundleList } from 'swag-client-common/utils/swag-bundles/validate-quantities-for-soa-bundles.util';
import { getCommonSizeIndexFromVariant, getVariantBySize } from '../../utils/custom-products.utils';
import { NO_APPAREL_SIZE } from '../../components/partials/swag-bundle/swag-bundle-notecard-step/constants';
import { calculateAdvanceDistributionStockInHand, getIsAdvanceDistribution, getProductionInHand, sortInProdTransactionInWarehouseQuantities } from '../../utils/advance-distributions.utils';
import { getDistributionCustomerInHandDate } from '../../utils/shipout.utils';
import { userBasedAmountApprovalSelector, userRoleBasedAmountApprovalSelector } from '../inventory/inventory.selectors';
import { promocodeSelector } from '../payment/payment.selectors';
import { productIdSelector, selectedBundlesSelector, selectedProductsSelector, singleBundleContentVariantsSelector, singleColorDataSelector, singleProductPossibleSizesSelector, singleProductQuantityPerSizeEnabledSelector, singleProductSelector, singleProductSingleColorVariantsSelector } from '../products/products.selectors';
import { PULL_FROM_INVENTORY_REDUX_FORM } from '../redux.constants';
import { bundleSizesSelector } from '../swag-bundles/swag-bundles.selectors';
import { calculateBundleQuantitiesByContentSelector } from '../swag-bundles/selectors/swag-bundles-calculation-bundle-content.selectors';
import { extendCustomProductWithTotalQuantity, getAvailableQuantityByVariant, getBreakdownByColor, getDescriptionByColor, getQuantitiesByVariant, getTotalQuantitiesByColor, getTotalQuantityToPull } from './pull-from-inventory.selectors.utils';
const pullFromInventorySelector = state => state.pullFromInventory;
export const shippingCostSelector = createSelector(pullFromInventorySelector, pullFromInventory => pullFromInventory.shippingCost);
export const taxAllSelector = createSelector(pullFromInventorySelector, pullFromInventory => pullFromInventory.taxes);
export const taxVatSelector = createSelector(taxAllSelector, taxes => taxes.vat);
export const taxDutyFeesSelector = createSelector(taxAllSelector, taxes => taxes.dutyFees);
export const taxFeesSelector = createSelector(taxAllSelector, taxes => taxes.fees);
export const taxAllSumSelector = createSelector(taxVatSelector, taxDutyFeesSelector, taxFeesSelector, (taxVat, taxDutyFees, feesSum) => taxVat + taxDutyFees + feesSum);
export const quantitiesSelector = createSelector(pullFromInventorySelector, pullFromInventory => pullFromInventory.quantities);
export const quantitiesByProductIdMapSelector = createSelector(pullFromInventorySelector, pullFromInventory => pullFromInventory.quantitiesByProductIdMap);
export const quantitiesByCustomProductIdMapSelector = createSelector(quantitiesByProductIdMapSelector, selectedBundlesSelector, (quantitiesByProductId, bundleProducts) => {
  return Object.keys(quantitiesByProductId).reduce((acc, productId) => {
    bundleProducts.some(bundleProduct => {
      var _bundleProduct$varian;
      const bundleVariant = (_bundleProduct$varian = bundleProduct.variants) === null || _bundleProduct$varian === void 0 ? void 0 : _bundleProduct$varian[0];
      if (bundleVariant && (bundleVariant === null || bundleVariant === void 0 ? void 0 : bundleVariant.parentId) == productId) {
        var _quantitiesByProductI;
        acc = _objectSpread({}, acc, {
          [String(bundleVariant._id)]: ((_quantitiesByProductI = quantitiesByProductId[productId]) === null || _quantitiesByProductI === void 0 ? void 0 : _quantitiesByProductI.quantitiesByColor) || {}
        });
        return true;
      }
    });
    return acc;
  }, {});
});
export const bundlesMapByQuantitySelector = createSelector(selectedBundlesSelector, state => state, (bundleProducts, state) => {
  return bundleProducts.reduce((acc, product) => {
    var _product$variants, _product$variants$;
    if (!((_product$variants = product.variants) !== null && _product$variants !== void 0 && (_product$variants$ = _product$variants[0]) !== null && _product$variants$ !== void 0 && _product$variants$._id)) {
      return acc;
    }
    const bundleQuantity = singleBundleTotalQuantitySelector(state, {
      productId: product._id
    });
    if (!bundleQuantity) {
      return acc;
    }
    return _objectSpread({}, acc, {
      [String(product.variants[0]._id)]: bundleQuantity
    });
  }, {});
});
export const bundleQuantitiesSelector = createSelector(quantitiesByProductIdMapSelector, quantitiesByProductIdMap => Object.values(quantitiesByProductIdMap).reduce((acc, {
  quantitiesByColor
}) => {
  Object.keys(quantitiesByColor).forEach(customProductId => {
    const quantity = quantitiesByColor[customProductId];
    const existsQuantity = acc[customProductId] | 0;
    acc = _objectSpread({}, acc, {
      [customProductId]: existsQuantity + quantity
    });
  });
  return acc;
}, {}));
export const currentQuantitiesByProductIdMapSelector = createSelector(productIdSelector, pullFromInventorySelector, (productId, pullFromInventory) => (pullFromInventory.quantitiesByProductIdMap[productId] || {}).quantitiesByColor || {});
export const currentMainQuantitiesByProductIdMapSelector = createSelector(productIdSelector, pullFromInventorySelector, (productId, pullFromInventory) => (pullFromInventory.quantitiesByProductIdMap[productId] || {}).mainQuantities || {});
export const sumQuantitiesByProductIdSelector = createSelector(currentQuantitiesByProductIdMapSelector, quantitiesByColor => Object.values(quantitiesByColor).reduce((total, quantity) => total + quantity, 0) || 0);
export const warehouseQuantitiesSelector = createSelector(pullFromInventorySelector, pullFromInventory => pullFromInventory.warehouseQuantities);
export const pfiIdSelector = createSelector(pullFromInventorySelector, pullFromInventory => pullFromInventory.pfiId);
export const readyToPaySelector = createSelector(pullFromInventorySelector, pullFromInventory => pullFromInventory.readyToPay);
export const possiblePFIDeliveriesSelector = createSelector(pullFromInventorySelector, pullFromInventory => pullFromInventory.possibleDeliveries);
export const selectedShippingMethodSelector = createSelector(pullFromInventorySelector, pullFromInventory => pullFromInventory.selectedShippingMethod);
export const packagingCostSelector = createSelector(pullFromInventorySelector, ({
  packagingCost
}) => packagingCost);
export const onlyPositiveQuantitiesSelector = createSelector(quantitiesSelector, quantities => Object.keys(quantities).reduce((acc, variantId) => {
  return quantities[variantId] ? _objectSpread({}, acc, {
    [variantId]: quantities[variantId]
  }) : acc;
}, {}));
export const nonEmptyQuantitiesSelector = createSelector(quantitiesSelector, quantities => Object.keys(quantities).reduce((nonEmpty, variantId) => {
  if (quantities[variantId] === 0) {
    return nonEmpty;
  }
  return _objectSpread({}, nonEmpty, {
    [variantId]: quantities[variantId]
  });
}, {}));
export const totalQuantitySelector = createSelector(quantitiesSelector, quantities => Object.keys(quantities).reduce((total, variantId) => {
  return total + quantities[variantId];
}, 0));
export const totalQuantityFromBundlesSelector = createSelector(quantitiesByProductIdMapSelector, quantitiesMap => Object.values(quantitiesMap).reduce((total, {
  quantitiesByColor
}) => {
  const totalByProduct = Object.values(quantitiesByColor).reduce((total, quantity) => total + quantity, 0);
  return total + totalByProduct;
}, 0));
export const singleProductSingleColorQuantitiesSelector = createSelector(singleProductSingleColorVariantsSelector, quantitiesSelector, singleProductQuantityPerSizeEnabledSelector, singleProductPossibleSizesSelector, (variants, quantities, perSizeEnabled, {
  options
}) => {
  if (!perSizeEnabled) {
    return variants.map(({
      _id
    }) => quantities[_id.toString()]);
  }
  return options.map((sizeName, sizeIndex) => {
    const variant = getVariantBySize({
      variants,
      sizeIndex,
      sizeName
    });
    if (!variant) {
      return 0;
    }
    return quantities[String(variant._id)] || 0;
  });
});
export const singleProductSingleColorTotalQuantitySelector = createSelector(singleProductSingleColorQuantitiesSelector, quantities => Object.values(quantities).reduce((total, quantity) => total + quantity, 0));
export const selectedFullProductsWithTotalQuantityToPullSelector = createSelector(selectedProductsSelector, quantitiesSelector, warehouseQuantitiesSelector, (state, props) => props.isTransfer, state => state, (products, quantities, warehouseQuantities, stockOnly = false, state) => {
  return products.map(product => extendCustomProductWithTotalQuantity({
    product,
    quantities,
    warehouseQuantities,
    stockOnly,
    state
  }));
});
export const singleProductTotalQuantityToPullSelector = createSelector(singleProductSelector, quantitiesSelector, getTotalQuantityToPull);
export const singleBundleQuantitiesSelector = createSelector(singleBundleContentVariantsSelector, quantitiesSelector, bundleSizesSelector, (variants, quantities, sizes) => {
  if (!sizes) {
    return [];
  }
  const onlyApparels = variants.filter(variant => checkIsApparelInBundle(variant));
  return sizes.map((sizeName, sizeIndex) => {
    const variant = getVariantBySize({
      variants: onlyApparels,
      sizeIndex,
      sizeName
    });
    if (!variant) {
      return 0;
    }
    return quantities[String(variant.customProductId)] || 0;
  });
});
export const singleQuantitiesByProductIdMapSelector = createSelector(productIdSelector, bundleSizesSelector, quantitiesByProductIdMapSelector, (productId, sizes, quantitiesMap) => {
  const productMap = quantitiesMap[productId] || {};
  const {
    mainQuantities = {}
  } = productMap;
  if (!(sizes !== null && sizes !== void 0 && sizes.length)) {
    const quantity = mainQuantities[NO_APPAREL_SIZE] || 0;
    return [quantity];
  }
  return sizes.map(sizeName => {
    const quantity = mainQuantities[sizeName] || 0;
    return quantity;
  });
});
export const sumQuantityByVariantIdSwagBundleMapSelector = createSelector(quantitiesByProductIdMapSelector, selectedProductsSelector, quantitiesSelector, (quantitiesMap, products, quantities) => {
  const sumQuantityByVariantId = {};
  Object.values(quantitiesMap).forEach(({
    quantitiesByColor
  }) => {
    Object.keys(quantitiesByColor).forEach(customProductId => {
      const existingSum = sumQuantityByVariantId[customProductId] || 0;
      sumQuantityByVariantId[customProductId] = existingSum + quantitiesByColor[customProductId];
    });
  });
  const usualProducts = products.filter(product => !isBundle(product.variants[0]));
  usualProducts.forEach(product => {
    product.variants.forEach(variant => {
      const quantity = quantities[String(variant._id)];
      const existingQuantity = sumQuantityByVariantId[String(variant._id)] || 0;
      sumQuantityByVariantId[String(variant._id)] = existingQuantity + quantity;
    });
  });
  return sumQuantityByVariantId;
});
export const isQuantityInputValidSelector = createSelector(selectedProductsSelector, quantitiesSelector, sumQuantityByVariantIdSwagBundleMapSelector, totalQuantitySelector, totalQuantityFromBundlesSelector, state => state, (products, quantities, sumQuantityByVariantId, totalQuantity, totalQuantityFromBundles, state) => {
  const bundleProducts = products.filter(product => isBundle(product.variants[0]));
  const nonBundleProducts = products.filter(product => !isBundle(product.variants[0]));
  const productsParams = bundleProducts.reduce((acc, product) => {
    const params = {
      productId: product._id
    };
    const variants = singleBundleContentVariantsSelector(state, params);
    return _objectSpread({}, acc, {
      [String(product._id)]: {
        variants,
        sizes: bundleSizesSelector(state, params) || [],
        bundleQuantitiesMap: currentQuantitiesByProductIdMapSelector(state, params),
        quantitiesMap: currentQuantitiesByProductIdMapSelector(state, params),
        bundleQuantityResult: calculateBundleQuantitiesByContentSelector(state, params),
        sumQuantityByVariantId,
        quantities: quantitiesSelector(state),
        totalToSend: singleBundleTotalQuantitySelector(state, params)
      }
    });
  }, {});
  const {
    isValid: isValidBundle = true
  } = validateQuantitiesForProductsSOABundleList(productsParams);
  const isEveryProductsVariantHasQuantityAvailable = !nonBundleProducts.length || nonBundleProducts.every(({
    variants
  }) => {
    return variants.every(product => {
      const {
        quantity = 0,
        inProductionQuantity = 0,
        inReserveQuantity = 0,
        _id
      } = product;
      const availableQuantityRaw = quantity + inProductionQuantity - inReserveQuantity;
      const availableQuantity = availableQuantityRaw > 0 ? availableQuantityRaw : 0;
      return _id && quantities[String(_id)] <= availableQuantity;
    });
  });
  const totalQuantityIsValid = nonBundleProducts.length ? totalQuantity > 0 : true;
  const totalQuantityBundleIsValid = bundleProducts.length ? totalQuantityFromBundles > 0 : true;
  return isEveryProductsVariantHasQuantityAvailable && isValidBundle && totalQuantityIsValid && totalQuantityBundleIsValid;
});
export const selectedFullProductsWithTotalQuantityToTransferSelector = createSelector(selectedProductsSelector, quantitiesSelector, warehouseQuantitiesSelector, state => state, (products, quantities, warehouseQuantities, state) => {
  return products.map(product => extendCustomProductWithTotalQuantity({
    product,
    quantities,
    warehouseQuantities,
    stockOnly: true,
    state
  }));
});
export const isTransferPossibleSelector = createSelector(selectedFullProductsWithTotalQuantityToTransferSelector, isQuantityInputValidSelector, (products, isQuantitiesValid) => {
  const areAllProductsWithExceededQuantity = products.every(({
    quantityExceeded
  }) => !quantityExceeded);
  return isQuantitiesValid && areAllProductsWithExceededQuantity;
});
export const hideSingleProductTotalQuantityToPullSelector = createSelector(singleProductSelector, product => {
  const {
    swagProductId,
    variants
  } = product;
  const isNoSize = !hasProductSizes(product) && variants.length <= 1;
  return isNoSize || isProductFullDesign(swagProductId);
});
export const formValuesSelector = createSelector(_getFormValues(PULL_FROM_INVENTORY_REDUX_FORM), values => values || {});
export const PFITotalChargesBeforeDiscountSelector = createSelector(packagingCostSelector, shippingCostSelector, taxAllSumSelector, (packagingCost, shippingCost, taxAllSum) => {
  return (packagingCost || 0) + (shippingCost || 0) + (taxAllSum || 0);
});
export const PFITotalChargesSelector = createSelector(PFITotalChargesBeforeDiscountSelector, promocodeSelector, totalPriceAfterPromocodeApplied);
export const isSOAApprovalFlowSelector = createSelector(userRoleBasedAmountApprovalSelector, userBasedAmountApprovalSelector, PFITotalChargesSelector, (roleBaseApproval, userBasedApproval, totalSOA) => {
  if (userBasedApproval.isApprovalFlow) {
    return totalSOA >= userBasedApproval.amount;
  }
  if (roleBaseApproval.isApprovalFlow) {
    return totalSOA >= roleBaseApproval.amount;
  }
  return false;
});
const isValidFormSelector = createSelector(_getFormValues(PULL_FROM_INVENTORY_REDUX_FORM), isValidPFIShippingForm);
export const isPullPossibleSelector = createSelector(isValidFormSelector, _isValid(PULL_FROM_INVENTORY_REDUX_FORM), isQuantityInputValidSelector, (...args) => args.every(Boolean));
export const shouldShowPFIProductNameSelector = createSelector(selectedProductsSelector, products => products.length === 1);
export const transferOrSendBeforeText = (state, {
  isTransfer
}) => {
  return `I would like to ${isTransfer ? 'transfer' : 'send'}:`;
};
export const possibleDeliveriesSelector = createSelector(possiblePFIDeliveriesSelector, possibleDeliveries => {
  const deliveries = sortPossibleDeliveries(renameDeliveryOptions(possibleDeliveries));
  return deliveries.map(delivery => _objectSpread({}, delivery, {
    totalCharges: delivery.shippingPrice
  }));
});
export const getIsSoaWithAdvanceDistributionSelector = createSelector(selectedProductsSelector, warehouseQuantitiesSelector, quantitiesSelector, (...args) => getIsAdvanceDistribution(...args));
export const advanceDistributionStockInHandSelector = createSelector(selectedProductsSelector, warehouseQuantitiesSelector, quantitiesSelector, (...args) => calculateAdvanceDistributionStockInHand(...args));
export const advanceDistributionStockInHandForBundleSelector = createSelector(selectedProductsSelector, warehouseQuantitiesSelector, currentQuantitiesByProductIdMapSelector, (selectedProducts, warehouseQuantities, quantitiesByProduct) => {
  return calculateAdvanceDistributionStockInHand(selectedProducts, warehouseQuantities, quantitiesByProduct);
});
export const estInHandDateSelector = createSelector(selectedShippingMethodSelector, possibleDeliveriesSelector, getIsSoaWithAdvanceDistributionSelector, advanceDistributionStockInHandSelector, (selectedMethod, possibleDeliveries, isAdvanceDistribution, advanceDistributionStockInHand) => {
  if (!selectedMethod || !possibleDeliveries.length) {
    return null;
  }
  const deliveryData = possibleDeliveries.find(({
    serviceCode
  }) => serviceCode === selectedMethod);
  if (!deliveryData) {
    return false;
  }
  const {
    minDays,
    maxDays
  } = deliveryData;
  return getDistributionCustomerInHandDate({
    minDays,
    maxDays,
    isAdvanceDistribution,
    advanceDistributionStockInHand
  });
});
export const shippingAddressUntouchedSelector = createSelector(pullFromInventorySelector, pullFromInventory => pullFromInventory.shippingAddressUntouched);
export const draftShipoutIdSelector = createSelector(pullFromInventorySelector, pullFromInventory => pullFromInventory.draftShipoutId);
export const singlePullProductUniqueColorsSelector = createSelector(singleProductSelector, warehouseQuantitiesSelector, quantitiesSelector, (state, props) => ({
  state,
  props
}), (product, warehouseQuantities, quantities, {
  state,
  props
}) => {
  if (!product) {
    return [];
  }
  const sortedWarehouseQuantities = sortInProdTransactionInWarehouseQuantities(warehouseQuantities);
  const {
    variants,
    swagProductId,
    psProduct
  } = product;
  const breakdownPerColors = {};
  const descriptionPerColors = {};
  const estInHandDateByColors = {};
  const withSizes = (swagProductId === null || swagProductId === void 0 ? void 0 : swagProductId.hasSize) && !(swagProductId !== null && swagProductId !== void 0 && swagProductId.sizeDependentPrice) || variants.length > 1 && isBox(swagProductId) || !!(psProduct !== null && psProduct !== void 0 && psProduct.sizeSettings) && (psProduct === null || psProduct === void 0 ? void 0 : psProduct.sizeSettings.options.length) > 1;
  const isTransfer = (props === null || props === void 0 ? void 0 : props.isTransfer) || false;
  const uniqueColorsMap = variants.reduce((colorsMap, variant) => {
    const {
      colorValue,
      colorName,
      images = [],
      inProductionQuantity
    } = variant;
    const variantId = String(variant._id);
    const color = colorsMap[colorValue];
    const warehouseLevel = sortedWarehouseQuantities[variantId];
    const quantitiesByColor = getTotalQuantitiesByColor({
      color,
      warehouseLevel
    });
    const size = singleProductPossibleSizesSelector(state, {
      selectedColor: colorValue,
      productId: (props === null || props === void 0 ? void 0 : props.productId) || ''
    });
    const sizeData = getBoxVariantSizeIndex(variant);
    const requestedQuantity = quantities[variantId];
    const variantEstInHand = requestedQuantity ? getProductionInHand(warehouseLevel, requestedQuantity) : null;
    const existingColorInHand = estInHandDateByColors[colorValue];
    if (!existingColorInHand || variantEstInHand && variantEstInHand > existingColorInHand) {
      estInHandDateByColors[colorValue] = variantEstInHand;
    }
    breakdownPerColors[colorValue] = getBreakdownByColor({
      withSizes,
      size,
      colorValue,
      breakdownPerColors,
      warehouseLevel,
      inProductionQuantity,
      sizeIndex: (sizeData === null || sizeData === void 0 ? void 0 : sizeData.sizeIndex) || 0
    });
    descriptionPerColors[colorValue] = getDescriptionByColor({
      isTransfer,
      colorValue,
      warehouseLevel,
      descriptionPerColors,
      requestedQuantity
    });
    const {
      inProductionBreakdown,
      inProductionReserve,
      inProductionTotal,
      inStockTotal,
      inReserveTotal
    } = quantitiesByColor;
    const description = descriptionPerColors[colorValue];
    return _objectSpread({}, colorsMap, {
      [colorValue]: {
        images,
        colorValue,
        colorName,
        inProductionReserve,
        inProductionBreakdown,
        inStock: inStockTotal,
        inProduction: inProductionTotal,
        inReserveQuantity: inReserveTotal,
        breakdown: breakdownPerColors[colorValue],
        description: description || {
          productsText: '',
          dateText: '',
          stockOnly: false
        }
      }
    });
  }, {});
  const colors = Object.keys(uniqueColorsMap).map(colorValue => uniqueColorsMap[colorValue]);
  return colors.map(({
    colorName,
    colorValue,
    inStock,
    inReserveQuantity,
    inProduction,
    inProductionBreakdown,
    breakdown,
    inProductionReserve,
    description
  }) => {
    const productionWithInHand = inProductionBreakdown.length ? inProductionBreakdown[0] : null;
    const productionInHand = estInHandDateByColors[colorValue];
    const validateQuantity = isTransfer ? inStock - (inReserveQuantity - inProductionReserve) : inStock + inProduction - inReserveQuantity;
    return {
      inStock,
      withSizes,
      breakdown,
      description,
      inProduction,
      validateQuantity,
      inReserveQuantity,
      available: validateQuantity,
      name: colorName,
      color: colorValue,
      isDisabled: false,
      inHandDate: productionWithInHand ? getInHandDateFormatted({
        inHandDate: productionInHand,
        isStrictInHandDate: productionWithInHand.isStrictInHandDate
      }) : ''
    };
  });
});
export const singlePullProductInStockSelector = createSelector(singleProductSelector, warehouseQuantitiesSelector, ({
  variants
}, warehouseQuantities) => {
  return variants.reduce((total, variant) => {
    var _warehouseQuantities$;
    const inStock = ((_warehouseQuantities$ = warehouseQuantities[String(variant._id)]) === null || _warehouseQuantities$ === void 0 ? void 0 : _warehouseQuantities$.inStock) || 0;
    return total + inStock;
  }, 0);
});
export const singleProductSingleColorAvailableQuantitiesSelector = createSelector(singleProductSingleColorVariantsSelector, singleProductQuantityPerSizeEnabledSelector, singleProductPossibleSizesSelector, warehouseQuantitiesSelector, (state, props) => props, (variants, perSizeEnabled, {
  options
}, warehouseQuantities, props) => {
  const isTransfer = (props === null || props === void 0 ? void 0 : props.isTransfer) || false;
  if (!perSizeEnabled) {
    return variants.map(variant => getAvailableQuantityByVariant({
      warehouseQuantities,
      variant,
      isTransfer
    }));
  }
  return options.map((sizeName, sizeIndex) => {
    const variant = getVariantBySize({
      variants,
      sizeIndex,
      sizeName
    });
    return getAvailableQuantityByVariant({
      warehouseQuantities,
      variant,
      isTransfer
    });
  });
});
export const singleProductSingleColorStockAndProductionQuantitiesSelector = createSelector(singleProductSingleColorVariantsSelector, singleProductQuantityPerSizeEnabledSelector, singleProductPossibleSizesSelector, warehouseQuantitiesSelector, (variants, perSizeEnabled, {
  options
}, warehouseQuantities) => {
  if (!perSizeEnabled) {
    return variants.map(variant => getQuantitiesByVariant({
      warehouseQuantities,
      variant
    }));
  }
  return options.map((sizeName, sizeIndex) => {
    const variant = getVariantBySize({
      variants,
      sizeIndex,
      sizeName
    });
    return getQuantitiesByVariant({
      warehouseQuantities,
      variant
    });
  });
});
export const singleProductSingleColorBundleQuantitiesSelector = createSelector(singleProductSingleColorVariantsSelector, singleProductSingleColorQuantitiesSelector, bundleQuantitiesSelector, singleProductQuantityPerSizeEnabledSelector, singleProductSingleColorStockAndProductionQuantitiesSelector, singleProductPossibleSizesSelector, (variants, quantities, bundleQuantities, perSizeEnabled, stockAndProductionBreakdown, {
  options
}) => {
  if (!perSizeEnabled) {
    return variants.map(({
      _id
    }) => bundleQuantities[_id.toString()] || 0);
  }
  let someProductIsIncludedInTheDistributionMoreThanOnce = false;
  const bundleQuantitiesByColor = options.map((sizeName, sizeIndex) => {
    const variant = getVariantBySize({
      variants,
      sizeIndex,
      sizeName
    });
    const warehouseLevel = stockAndProductionBreakdown[sizeIndex];
    if (!variant || !warehouseLevel) {
      return 0;
    }
    const {
      inProduction,
      inStock,
      inReserveProduction,
      inReserveStock
    } = warehouseLevel;
    const availableStock = inStock - inReserveStock;
    const availableProduction = inProduction - inReserveProduction;
    const available = availableStock + availableProduction;
    const variantQuantity = quantities[sizeIndex] || 0;
    const bundleQuantity = bundleQuantities[String(variant._id)] || 0;
    const sumQuantities = variantQuantity + bundleQuantity;
    const isInValid = available < sumQuantities;
    if (available < sumQuantities) {
      someProductIsIncludedInTheDistributionMoreThanOnce = true;
    }
    return {
      bundleQuantity,
      isInValid
    };
  });
  return {
    bundleQuantitiesByColor,
    someProductIsIncludedInTheDistributionMoreThanOnce
  };
});
export const someProductIsIncludedInTheDistributionMoreThanOnceSelector = createSelector(singleProductSingleColorBundleQuantitiesSelector, ({
  someProductIsIncludedInTheDistributionMoreThanOnce
}) => {
  return someProductIsIncludedInTheDistributionMoreThanOnce;
});
export const singleProductSingleColorOutOfStockSizesSelector = createSelector(singleProductSingleColorAvailableQuantitiesSelector, quantitiesWithStock => {
  return quantitiesWithStock.reduce((outOfStockSizes, quantity, sizeIndex) => {
    if (quantity <= 0) {
      outOfStockSizes.add(sizeIndex);
    }
    return outOfStockSizes;
  }, new Set());
});
export const singleProductSingleColorNotAvailableSizesSelector = createSelector(singleProductSingleColorOutOfStockSizesSelector, outOfStockSizes => {
  return new Set([...Array.from(outOfStockSizes)]);
});
export const variantsWithQuantitiesSelector = createSelector(singleColorDataSelector, singleProductSingleColorQuantitiesSelector, singleProductSingleColorStockAndProductionQuantitiesSelector, singleProductSingleColorBundleQuantitiesSelector, (state, props) => props, (variant, quantities, stockAndProductionBreakdown, {
  bundleQuantitiesByColor
}, props) => {
  const colorBehavior = quantities.map((quantity, sizeIndex) => {
    const warehouseLevel = stockAndProductionBreakdown[sizeIndex];
    if (!warehouseLevel || props !== null && props !== void 0 && props.isTransfer) {
      return {
        color: InputColor.available
      };
    }
    const {
      inProduction,
      inStock,
      inReserveProduction,
      inReserveStock
    } = warehouseLevel;
    const availableStock = inStock - inReserveStock;
    const availableProduction = inProduction - inReserveProduction;
    const isProductionUsed = quantity > availableStock && availableProduction + availableStock >= quantity;
    if (isProductionUsed) {
      return {
        color: InputColor.potentiallyAvailable
      };
    }
    return {
      color: InputColor.available
    };
  });
  return [_objectSpread({}, variant, {
    quantities,
    bundleQuantities: bundleQuantitiesByColor,
    colorBehavior
  })];
});
export const singleBundleTotalQuantitySelector = createSelector(singleQuantitiesByProductIdMapSelector, quantities => Object.values(quantities).reduce((total, quantity) => total + quantity, 0));
export const bundleGetSizeBreakdownForApparel = createSelector(singleBundleContentVariantsSelector, bundleSizesSelector, (_, {
  variant
}) => variant, (variants, sizes, variant) => {
  const onlyApparels = variants.filter(variant => checkIsApparelInBundle(variant));
  const {
    parentId,
    sizeSettings,
    colorValue
  } = variant;
  if (!sizeSettings) {
    return null;
  }
  const variantSizesByColor = onlyApparels.filter(apparel => apparel.parentId === parentId && apparel.colorValue === colorValue);
  const stock = sizes === null || sizes === void 0 ? void 0 : sizes.map((sizeName, sizeIndex) => {
    const variant = variantSizesByColor.find(variant => {
      const commonSizeIndex = getCommonSizeIndexFromVariant({
        variant,
        commonSizes: sizes
      });
      return commonSizeIndex === sizeIndex;
    });
    if (!variant) {
      return null;
    }
    const {
      inStockQuantity,
      inReserveStockQuantity
    } = variant;
    return {
      sizeName,
      available: inStockQuantity,
      inReserve: inReserveStockQuantity
    };
  }).filter(product => !!product);
  const production = sizes === null || sizes === void 0 ? void 0 : sizes.map((sizeName, sizeIndex) => {
    const variant = variantSizesByColor.find(variant => {
      const commonSizeIndex = getCommonSizeIndexFromVariant({
        variant,
        commonSizes: sizes
      });
      return commonSizeIndex === sizeIndex;
    });
    if (!variant) {
      return null;
    }
    const {
      inProductionQuantity,
      inReserveProductionQuantity
    } = variant;
    return {
      sizeName,
      available: inProductionQuantity,
      inReserve: inReserveProductionQuantity
    };
  }).filter(product => !!product);
  return {
    production,
    stock
  };
});