import { createApi } from 'swag-client-common/libs/rapi';
const baseUrl = '/custom-store-api/v1/inventory/swag-bundle';
export const SwagBundlesApi = createApi({
  proceed: {
    url: `${baseUrl}/proceed`,
    method: 'post'
  },
  orderMoreAddons: {
    url: `${baseUrl}/order-more-addons`,
    method: 'post'
  },
  delete: {
    url: `${baseUrl}/remove/:productId`,
    method: 'delete'
  }
});