import { Price } from 'swag-client-common/utils/price';
export function getItemPriceFromProduct({
  orderItem,
  product,
  blendedQuantity,
  screenPrice,
  totalQuantityForSharedItems
}) {
  const price = Price.getPrice({
    orderItem,
    product,
    blendedQuantity,
    screenPrice,
    totalQuantityForSharedItems
  });
  return price;
}