import { checkIsBundleAddon } from 'swag-client-common/utils/product.utils';
export const calculateTotalTransactions = variant => variant.inStockQuantity - (variant.inReserveStockQuantity || 0) + variant.inProductionQuantity - (variant.inReserveProductionQuantity || 0);
export const validateQuantitiesForProductsSOABundle = params => {
  const {
    variants: bundleVariants,
    sumQuantityByVariantId,
    bundleQuantitiesMap,
    bundleQuantityResult,
    quantities,
    totalToSend
  } = params;
  const {
    availableToSend,
    inStock,
    inProduction
  } = bundleQuantityResult;
  const isValid = true;
  let errorMessage;
  const variants = bundleVariants.filter(variant => !checkIsBundleAddon(variant));
  const addonVariants = bundleVariants.filter(variant => checkIsBundleAddon(variant));
  if (availableToSend > inStock + inProduction) {
    return {
      isValid: false,
      errorMessage: `Total quantity ${availableToSend} more than current inStock + inProduction: (${inStock} + ${inProduction})`
    };
  }
  for (const variant of addonVariants) {
    if (calculateTotalTransactions(variant) < totalToSend) {
      return {
        isValid: false,
        errorMessage: "Quantity of 'Available to send' can't be more than add-ons bought."
      };
    }
  }
  for (const variant of variants) {
    const selectedQuantity = bundleQuantitiesMap[variant.customProductId];
    const quantityOutsideBundle = quantities[variant.customProductId];
    const available = calculateTotalTransactions(variant);
    if (selectedQuantity + quantityOutsideBundle > available) {
      return {
        isValid: false,
        errorMessage: `Quantity for variant ${variant.name} is set more than available`
      };
    }
    const sumQuantity = sumQuantityByVariantId[String(variant.customProductId)] || 0;
    if (sumQuantity > available) {
      return {
        isValid: false,
        errorMessage: `This product is used in different places, and the overall quantity now is ${sumQuantity}`
      };
    }
  }
  return {
    isValid,
    errorMessage
  };
};
export const validateQuantitiesForProductsSOABundleList = productsParams => {
  let isValidBundles = true;
  let errorMessage;
  Object.keys(productsParams).forEach(productId => {
    const data = productsParams[productId];
    const {
      isValid,
      errorMessage: error
    } = validateQuantitiesForProductsSOABundle(data);
    if (!isValid) {
      isValidBundles = false;
      errorMessage = error;
    }
  });
  return {
    isValid: isValidBundles,
    errorMessage
  };
};