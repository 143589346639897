function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { ProductApi } from 'swag-client-common/api/product.api';
import { createAsyncAction, errorAction } from 'swag-client-common/libs/redux-helpers';
import { hideLoader, showLoader } from 'swag-client-common/redux/common/common.actions';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { SPECIAL_PRODUCT_TYPES } from 'swag-common/constants/product.constants';
import { USER_ERROR_ACTIONS } from 'swag-common/constants/user-actions';
import { calculateMaxPossibleQuantityOfBundles } from 'swag-common/utils/swag-bundles/calculate-max-possible-quantity-of-bundles.util';
import { InventoryApi } from '../../../api/inventory.api';
import { OrderApi } from '../../../api/order.api';
import { SWAG_BUNDLE_ADDONS_TYPES } from '../../../utils/constants';
import { selectedInventorySelector } from '../../inventory/inventory.selectors';
import { selectedProductsDataForCalculationBundlesQuantitySelector } from '../selectors/swag-bundles-calculation-selected-products.selectors';
import { swagBundlesIsNotecardSelectedToCreateSelector } from '../selectors/swag-bundles-notecard-data.selectors';
import { findPackagingInOrderMoreBundleSelector, selectedOrderMoreBundleHasNotecardSelector } from '../selectors/swag-bundles-order-more.selectors';
import { swagBundlesCurrentlyMaxPossibleQuantitiesOfBundlesSelector, swagBundlesSelectedProductsVariantsWithQuantitySelector } from '../selectors/swag-bundles-selected-variants-with-quantity.selectors';
import { availableToSendBundlesIncludeAddonsSelector, notecardMinQtySelector, notecardQuantitySelector, notecardReadySelector, packageMinQtySelector, packageQuantitySelector, packagingReadySelector, shippingAndTaxesDataSelector } from '../selectors/swag-bundles-shipping-data.selectors';
import { swagBundleSelectedBoxSelector, swagBundlesSelectedProductsAndAddonsSelector } from '../swag-bundles.selectors';
import { SWAG_BUNDLES_ADMIN_PREFIX } from './swag-bundles-common.actions';
export const CALCULATE_SHIPPING = `${SWAG_BUNDLES_ADMIN_PREFIX}_CALCULATE_SHIPPING`;
export const CALCULATE_SHIPPING_OPTIONS_REQUEST = `${CALCULATE_SHIPPING}_REQUEST`;
export const CALCULATE_SHIPPING_OPTIONS_SUCCESS = `${CALCULATE_SHIPPING}_SUCCESS`;
export const CALCULATE_SHIPPING_OPTIONS_FAIL = `${CALCULATE_SHIPPING}_FAIL`;
const requestIsPossible = ({
  isNotPreselected,
  addonIsSelected,
  isInitialCalculation,
  addonsCosts
}) => {
  return isNotPreselected && addonIsSelected && (isInitialCalculation ? isInitialCalculation && !addonsCosts : true);
};
export const calculateShippingCosts = createAsyncAction({
  preFn: dispatch => {
    dispatch(showLoader());
    dispatch({
      type: CALCULATE_SHIPPING_OPTIONS_REQUEST
    });
  },
  transformData: ({
    type,
    isInitialCalculation,
    isOrderMoreRequest,
    isRequestBothAddons
  }, getState) => {
    var _foundPackage$swagPro;
    const state = getState();
    const selectedProductsAndAddons = swagBundlesSelectedProductsAndAddonsSelector(state);
    const shippingAndTaxesData = shippingAndTaxesDataSelector(state);
    const foundPackage = findPackagingInOrderMoreBundleSelector(state);
    const packageType = swagBundleSelectedBoxSelector(state) || (foundPackage === null || foundPackage === void 0 ? void 0 : (_foundPackage$swagPro = foundPackage.swagProductId) === null || _foundPackage$swagPro === void 0 ? void 0 : _foundPackage$swagPro.specialProductType);
    const isNotecardSelectedToCreate = swagBundlesIsNotecardSelectedToCreateSelector(state);
    const selectedOrderMoreBundleHasNotecard = selectedOrderMoreBundleHasNotecardSelector(state);
    const {
      selectedPackages,
      selectedNotecards
    } = selectedProductsAndAddons;
    const {
      packageQuantity,
      notecardQuantity,
      packageCosts,
      notecardCosts
    } = shippingAndTaxesData;
    let packageNeedRequest;
    let notecardNeedRequest;
    if (type === SWAG_BUNDLE_ADDONS_TYPES.PACKAGE || isRequestBothAddons) {
      packageNeedRequest = requestIsPossible({
        isNotPreselected: !selectedPackages.length,
        addonIsSelected: !!packageType && packageType !== SPECIAL_PRODUCT_TYPES.NO_BOX,
        isInitialCalculation,
        addonsCosts: packageCosts
      });
    }
    if (type === SWAG_BUNDLE_ADDONS_TYPES.NOTECARD || isRequestBothAddons) {
      notecardNeedRequest = requestIsPossible({
        isNotPreselected: !selectedNotecards.length,
        addonIsSelected: isOrderMoreRequest ? selectedOrderMoreBundleHasNotecard : isNotecardSelectedToCreate,
        isInitialCalculation,
        addonsCosts: notecardCosts
      });
    }
    const packageOptions = _objectSpread({}, (packageCosts === null || packageCosts === void 0 ? void 0 : packageCosts.serviceCode) && {
      serviceCode: packageCosts.serviceCode
    }, {
      quantity: packageQuantity,
      specialProductType: packageType
    });
    const notecardOptions = _objectSpread({}, (notecardCosts === null || notecardCosts === void 0 ? void 0 : notecardCosts.serviceCode) && {
      serviceCode: notecardCosts.serviceCode
    }, {
      quantity: notecardQuantity
    });
    return {
      packageOptions: packageNeedRequest ? packageOptions : null,
      notecardOptions: notecardNeedRequest ? notecardOptions : null
    };
  },
  fn: OrderApi.calculateSwagBundleAddonsCosts,
  debounceTime: 700,
  success: body => ({
    type: CALCULATE_SHIPPING_OPTIONS_SUCCESS,
    payload: body
  }),
  error: errorAction(CALCULATE_SHIPPING_OPTIONS_FAIL),
  postSuccess: dispatch => dispatch(hideLoader()),
  postError: (dispatch, _, err) => {
    dispatch(hideLoader());
    let errMessage = USER_ERROR_ACTIONS.SOMETHING_WENT_WRONG;
    if (err.status === 400 && err !== null && err !== void 0 && err.message) {
      errMessage = 'You need to add more notecard/boxes because these positions have min quantity for order';
    }
    dispatch(showNotification({
      text: errMessage
    }));
  }
});
export const ADDONS_MIN_QUANTITY = `${SWAG_BUNDLES_ADMIN_PREFIX}_MIN_QUANTITY`;
export const ADDONS_MIN_QUANTITY_REQUEST = `${ADDONS_MIN_QUANTITY}_REQUEST`;
export const ADDONS_MIN_QUANTITY_SUCCESS = `${ADDONS_MIN_QUANTITY}_SUCCESS`;
export const ADDONS_MIN_QUANTITY_FAIL = `${ADDONS_MIN_QUANTITY}_FAIL`;
export const getAddonsMinQuantity = createAsyncAction({
  preFn: dispatch => {
    dispatch(showLoader());
    dispatch({
      type: ADDONS_MIN_QUANTITY_REQUEST
    });
  },
  fn: ProductApi.getAddonsMinQuantityForSwagBundle,
  debounceTime: 700,
  success: body => ({
    type: ADDONS_MIN_QUANTITY_SUCCESS,
    payload: body
  }),
  error: errorAction(ADDONS_MIN_QUANTITY_FAIL),
  postSuccess: (dispatch, getState) => {
    dispatch(hideLoader());
  },
  postError: dispatch => dispatch(hideLoader())
});
export const CALCULATE_DEFAULT_QUANTITY = `${SWAG_BUNDLES_ADMIN_PREFIX}_CALCULATE_DEFAULT_QUANTITY`;
export const calculateDefaultQuantity = () => (dispatch, getState) => {
  const state = getState();
  const shippingAndTaxesData = shippingAndTaxesDataSelector(state);
  const {
    availableToSend
  } = swagBundlesCurrentlyMaxPossibleQuantitiesOfBundlesSelector(state);
  const {
    packageMinQuantity,
    notecardMinQuantity
  } = shippingAndTaxesData;
  const packageQuantity = availableToSend > packageMinQuantity ? availableToSend : packageMinQuantity;
  const notecardQuantity = availableToSend > notecardMinQuantity ? availableToSend : notecardMinQuantity;
  dispatch({
    type: CALCULATE_DEFAULT_QUANTITY,
    payload: {
      packageQuantity,
      notecardQuantity
    }
  });
  dispatch(calculateQuantitiesOfBundlesIncludeAddons());
  dispatch(getProductsInStockDate());
};
export const setDefaultQuantityByMinQuantity = () => (dispatch, getState) => {
  const state = getState();
  const shippingAndTaxesData = shippingAndTaxesDataSelector(state);
  const {
    packageMinQuantity,
    notecardMinQuantity
  } = shippingAndTaxesData;
  dispatch({
    type: CALCULATE_DEFAULT_QUANTITY,
    payload: {
      packageQuantity: packageMinQuantity,
      notecardQuantity: notecardMinQuantity
    }
  });
};
export const QUANTITY_PACKAGE_CHANGE = `${SWAG_BUNDLES_ADMIN_PREFIX}_QUANTITY_PACKAGE_CHANGE`;
export const quantityPackageChange = ({
  quantity,
  withBundleQuantityCalculation = true,
  isOrderMoreRequest = false
}) => (dispatch, getState) => {
  const state = getState();
  const packageValue = packageQuantitySelector(state);
  const packageMinQty = packageMinQtySelector(state);
  if (quantity !== packageValue && quantity >= packageMinQty) {
    dispatch({
      type: QUANTITY_PACKAGE_CHANGE,
      payload: quantity
    });
    dispatch(calculateShippingCosts({
      type: SWAG_BUNDLE_ADDONS_TYPES.PACKAGE,
      isOrderMoreRequest
    }));
    if (withBundleQuantityCalculation) {
      dispatch(calculateQuantitiesOfBundlesIncludeAddons());
      dispatch(getProductsInStockDate());
    }
  }
};
export const QUANTITY_NOTECARD_CHANGE = `${SWAG_BUNDLES_ADMIN_PREFIX}_QUANTITY_NOTECARD_CHANGE`;
export const quantityNotecardChange = ({
  quantity,
  withBundleQuantityCalculation = true,
  isOrderMoreRequest = false
}) => (dispatch, getState) => {
  const state = getState();
  const packageValue = notecardQuantitySelector(state);
  const notecardMinQty = notecardMinQtySelector(state);
  if (quantity !== packageValue && quantity >= notecardMinQty) {
    dispatch({
      type: QUANTITY_NOTECARD_CHANGE,
      payload: quantity
    });
    dispatch(calculateShippingCosts({
      type: SWAG_BUNDLE_ADDONS_TYPES.NOTECARD,
      isOrderMoreRequest
    }));
    if (withBundleQuantityCalculation) {
      dispatch(calculateShippingCosts({
        type: SWAG_BUNDLE_ADDONS_TYPES.PACKAGE
      }));
      dispatch(getProductsInStockDate());
    }
  }
};
export const SERVICE_CODE_PACKAGE_CHANGE = `${SWAG_BUNDLES_ADMIN_PREFIX}_SERVICE_CODE_PACKAGE_CHANGE`;
export const serviceCodePackageChange = (serviceCode, isOrderMoreRequest) => dispatch => {
  dispatch({
    type: SERVICE_CODE_PACKAGE_CHANGE,
    payload: serviceCode
  });
  dispatch(calculateShippingCosts({
    type: SWAG_BUNDLE_ADDONS_TYPES.PACKAGE,
    isOrderMoreRequest
  }));
};
export const SERVICE_CODE_NOTECARD_CHANGE = `${SWAG_BUNDLES_ADMIN_PREFIX}_SERVICE_CODE_NOTECARD_CHANGE`;
export const serviceCodeNotecardChange = (serviceCode, isOrderMoreRequest) => dispatch => {
  dispatch({
    type: SERVICE_CODE_NOTECARD_CHANGE,
    payload: serviceCode
  });
  dispatch(calculateShippingCosts({
    type: SWAG_BUNDLE_ADDONS_TYPES.NOTECARD,
    isOrderMoreRequest
  }));
};
export const BUNDLES_QUANTITIES_AVAILABLE_TO_SEND_CHANGE = `${SWAG_BUNDLES_ADMIN_PREFIX}_BUNDLES_QUANTITIES_AVAILABLE_TO_SEND_CHANGE`;
export const changeQuantitiesOfAvailableToSendBundles = quantity => dispatch => {
  dispatch({
    type: BUNDLES_QUANTITIES_AVAILABLE_TO_SEND_CHANGE,
    payload: quantity
  });
};
export const calculateQuantitiesOfBundlesIncludeAddons = () => (dispatch, getState) => {
  const state = getState();
  const productsCalculationData = selectedProductsDataForCalculationBundlesQuantitySelector(state);
  const addOnsCalculationData = [];
  const isNotecardSelectedToCreate = notecardReadySelector(state);
  const notecardQuantitySelected = notecardQuantitySelector(state);
  if (isNotecardSelectedToCreate) {
    const notecardCalculationData = {
      inStockQuantity: 0,
      inProductionQuantity: notecardQuantitySelected,
      quantityPerBundle: 1
    };
    addOnsCalculationData.push(notecardCalculationData);
  }
  const isPackageSelectedToCreate = packagingReadySelector(state);
  const packageQuantitySelected = packageQuantitySelector(state);
  if (isPackageSelectedToCreate) {
    const packageCalculationData = {
      inStockQuantity: 0,
      inProductionQuantity: packageQuantitySelected,
      quantityPerBundle: 1
    };
    addOnsCalculationData.push(packageCalculationData);
  }
  const calculatedQuantitiesOfBundles = calculateMaxPossibleQuantityOfBundles([...productsCalculationData, ...addOnsCalculationData]);
  dispatch(changeQuantitiesOfAvailableToSendBundles(calculatedQuantitiesOfBundles.availableToSend));
};
export const PRODUCTS_IN_STOCK_DATE = `${SWAG_BUNDLES_ADMIN_PREFIX}_PRODUCTS_IN_STOCK_DATE`;
export const getProductsInStockDate = createAsyncAction({
  transformData: (data, getState) => {
    const inventoryId = selectedInventorySelector(getState());
    const products = swagBundlesSelectedProductsVariantsWithQuantitySelector(getState());
    const numberOFBundles = availableToSendBundlesIncludeAddonsSelector(getState());
    return {
      inventoryId,
      productsMap: JSON.stringify(Object.entries(products).reduce((acc, [productId, data]) => {
        acc[productId] = data.map(({
          colorValue,
          quantity
        }) => ({
          colorValue,
          quantity: quantity * numberOFBundles
        }));
        return acc;
      }, {}))
    };
  },
  fn: InventoryApi.getBundleInStockDateSimple,
  success: body => ({
    type: PRODUCTS_IN_STOCK_DATE,
    payload: body
  })
});
export const RESET_CALCULATION_SHIPPING_STATE = `${SWAG_BUNDLES_ADMIN_PREFIX}_RESET_CALCULATION_SHIPPING_STATE`;
export const resetCalculationShippingState = () => ({
  type: RESET_CALCULATION_SHIPPING_STATE
});