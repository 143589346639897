import { createSelector } from 'reselect';
import { SPECIAL_PRODUCT_TYPES } from 'swag-common/constants/product.constants';
import { swagBundleSelectedBoxSelector, swagBundlesSelectedProductsAndAddonsSelector, swagBundlesSelector } from '../swag-bundles.selectors';
import { swagBundlesIsNotecardSelectedToCreateSelector } from './swag-bundles-notecard-data.selectors';
export const shippingAndTaxesDataSelector = createSelector(swagBundlesSelector, ({
  shippingAndTaxesData
}) => shippingAndTaxesData);
export const notecardMinQtySelector = createSelector(shippingAndTaxesDataSelector, ({
  notecardMinQuantity
}) => notecardMinQuantity);
export const packageMinQtySelector = createSelector(shippingAndTaxesDataSelector, ({
  packageMinQuantity
}) => packageMinQuantity);
export const notecardDataSelector = createSelector(shippingAndTaxesDataSelector, ({
  notecardCosts
}) => notecardCosts);
export const packageDataSelector = createSelector(shippingAndTaxesDataSelector, ({
  packageCosts
}) => packageCosts);
export const packagingReadySelector = createSelector(packageDataSelector, swagBundleSelectedBoxSelector, swagBundlesSelectedProductsAndAddonsSelector, (packageData, type, {
  selectedPackages
}) => Boolean(!selectedPackages.length && packageData && type !== SPECIAL_PRODUCT_TYPES.NO_BOX));
export const notecardReadySelector = createSelector(notecardDataSelector, swagBundlesIsNotecardSelectedToCreateSelector, swagBundlesSelectedProductsAndAddonsSelector, (notecardData, notecardSelected, {
  selectedNotecards
}) => Boolean(!selectedNotecards.length && notecardData && notecardSelected));
export const isShippingDataDisabledSelector = createSelector(shippingAndTaxesDataSelector, ({
  packageMinQuantity,
  notecardMinQuantity,
  packageQuantity,
  notecardQuantity
}) => notecardQuantity < notecardMinQuantity || packageQuantity < packageMinQuantity);
export const notecardQuantitySelector = createSelector(shippingAndTaxesDataSelector, ({
  notecardQuantity
}) => notecardQuantity);
export const singleAddonSelector = createSelector(packagingReadySelector, notecardReadySelector, (notecardReady, packagingReady) => Boolean(notecardReady && !packagingReady || !notecardReady && packagingReady));
export const packageQuantitySelector = createSelector(shippingAndTaxesDataSelector, ({
  packageQuantity
}) => packageQuantity);
export const totalAddonsCostSelector = createSelector(packageDataSelector, notecardDataSelector, (packageData, notecardData) => {
  var _packageData$total, _notecardData$total;
  return ((_packageData$total = packageData === null || packageData === void 0 ? void 0 : packageData.total) !== null && _packageData$total !== void 0 ? _packageData$total : 0) + ((_notecardData$total = notecardData === null || notecardData === void 0 ? void 0 : notecardData.total) !== null && _notecardData$total !== void 0 ? _notecardData$total : 0);
});
export const availableToSendBundlesIncludeAddonsSelector = createSelector(shippingAndTaxesDataSelector, ({
  bundlesQuantitiesAvailableToSendIncludeAddOns
}) => bundlesQuantitiesAvailableToSendIncludeAddOns);
export const swagBundleTotalAvailableForDistributionSelector = createSelector(shippingAndTaxesDataSelector, packageDataSelector, notecardDataSelector, ({
  productsInStockDate
}, packageData, notecardData) => {
  const dates = [new Date(productsInStockDate).valueOf()];
  if (notecardData) {
    dates.push(new Date(notecardData.inHandDate).valueOf());
  }
  if (packageData) {
    dates.push(new Date(packageData.inHandDate).valueOf());
  }
  return new Date(Math.max(...dates)).toISOString();
});
export const swagBundleTotalItemsPriceSelector = createSelector(shippingAndTaxesDataSelector, packageDataSelector, notecardDataSelector, ({
  packageQuantity,
  notecardQuantity
}, packageData, notecardData) => ((packageData === null || packageData === void 0 ? void 0 : packageData.productPrice) || 0) * packageQuantity + ((notecardData === null || notecardData === void 0 ? void 0 : notecardData.productPrice) || 0) * notecardQuantity);
export const swagBundleTotalTaxesPriceSelector = createSelector(packageDataSelector, notecardDataSelector, (packageData, notecardData) => ((packageData === null || packageData === void 0 ? void 0 : packageData.taxesPrice) || 0) + ((notecardData === null || notecardData === void 0 ? void 0 : notecardData.taxesPrice) || 0));
export const swagBundleTotalShippingPriceSelector = createSelector(packageDataSelector, notecardDataSelector, (packageData, notecardData) => ((packageData === null || packageData === void 0 ? void 0 : packageData.shippingPrice) || 0) + ((notecardData === null || notecardData === void 0 ? void 0 : notecardData.shippingPrice) || 0));