import { createSelector } from 'reselect';
import { FilterIds } from 'swag-common/constants/main-swag.constants';
const boxAndInventoryFacetsSelector = createSelector(state => state.searchResults.facets, facets => facets.boxAndInventory || {});
export const boxAndInventorySelector = createSelector(boxAndInventoryFacetsSelector, boxAndInventoryFacets => {
  const filterData = boxAndInventoryFacets.global || [{
    name: FilterIds.isBox,
    id: FilterIds.isBox,
    value: false
  }, {
    name: FilterIds.isInventory,
    id: FilterIds.isInventory,
    value: false
  }, {
    name: FilterIds.isSuperSpeed,
    id: FilterIds.isSuperSpeed,
    value: false
  }];
  return {
    filterName: 'Box and Inventory',
    filterId: FilterIds.boxAndInventory,
    filterData
  };
});
export const shopCustomColorsSelector = () => ({
  secondaryColor: '',
  primaryColor: ''
});