import { SPECIAL_PRODUCT_TYPES } from 'swag-common/constants/product.constants';
import { isBoxType } from 'swag-common/utils/custom-store-product/product-is-box-type.util';
import { productByProductIdSelector } from '../../products/products.selectors';
import { SWAG_BUNDLES_ADMIN_PREFIX } from './swag-bundles-common.actions';
export const SWAG_BUNDLES_POPULATE_PRODUCTS_FROM_INVENTORY = `${SWAG_BUNDLES_ADMIN_PREFIX}_POPULATE_PRODUCTS_FROM_INVENTORY`;
export const SWAG_BUNDLES_SELECT_PRODUCT = `${SWAG_BUNDLES_ADMIN_PREFIX}_SELECT_PRODUCT`;
export const SWAG_BUNDLES_UNSELECT_PRODUCT = `${SWAG_BUNDLES_ADMIN_PREFIX}_UNSELECT_PRODUCT`;
export const SWAG_BUNDLES_SELECT_NOTECARD = `${SWAG_BUNDLES_ADMIN_PREFIX}_SELECT_NOTECARD`;
export const SWAG_BUNDLES_UNSELECT_NOTECARD = `${SWAG_BUNDLES_ADMIN_PREFIX}_UNSELECT_NOTECARD`;
export const SWAG_BUNDLES_SELECT_PACKAGES = `${SWAG_BUNDLES_ADMIN_PREFIX}_SELECT_PACKAGE`;
export const SWAG_BUNDLES_UNSELECT_PACKAGES = `${SWAG_BUNDLES_ADMIN_PREFIX}_UNSELECT_PACKAGE`;
const getProductActionType = product => {
  const {
    specialProductType
  } = (product === null || product === void 0 ? void 0 : product.swagProductId) || {};
  if (specialProductType === SPECIAL_PRODUCT_TYPES.BOX_NOTECARD) {
    return SWAG_BUNDLES_SELECT_NOTECARD;
  }
  if (product !== null && product !== void 0 && product.swagProductId && isBoxType(product === null || product === void 0 ? void 0 : product.swagProductId)) {
    return SWAG_BUNDLES_SELECT_PACKAGES;
  }
  return SWAG_BUNDLES_SELECT_PRODUCT;
};
const getUnselectProductActionType = product => {
  const {
    specialProductType
  } = (product === null || product === void 0 ? void 0 : product.swagProductId) || {};
  if (specialProductType === SPECIAL_PRODUCT_TYPES.BOX_NOTECARD) {
    return SWAG_BUNDLES_UNSELECT_NOTECARD;
  }
  if (product !== null && product !== void 0 && product.swagProductId && isBoxType(product === null || product === void 0 ? void 0 : product.swagProductId)) {
    return SWAG_BUNDLES_UNSELECT_PACKAGES;
  }
  return SWAG_BUNDLES_UNSELECT_PRODUCT;
};
const categorizeProducts = (productIds, getState) => {
  const state = getState();
  const selectedProducts = [];
  const selectedNotecards = [];
  const selectedPackages = [];
  productIds.forEach(productId => {
    const product = productByProductIdSelector(state, {
      productId: String(productId)
    });
    const actionType = getProductActionType(product);
    if (actionType === SWAG_BUNDLES_SELECT_NOTECARD) {
      selectedNotecards.push(productId);
      return;
    }
    if (actionType === SWAG_BUNDLES_SELECT_PACKAGES) {
      selectedPackages.push(productId);
      return;
    }
    selectedProducts.push(productId);
  });
  return {
    selectedProducts,
    selectedNotecards,
    selectedPackages
  };
};
export const addProductWithInformationToSwagBundles = productId => (dispatch, getState) => {
  const state = getState();
  const product = productByProductIdSelector(state, {
    productId: String(productId)
  });
  const actionType = getProductActionType(product);
  dispatch({
    type: actionType,
    payload: productId
  });
};
export const removeProductWithInformationFromSwagBundles = productId => (dispatch, getState) => {
  const state = getState();
  const product = productByProductIdSelector(state, {
    productId: String(productId)
  });
  const actionType = getUnselectProductActionType(product);
  dispatch({
    type: actionType,
    payload: productId
  });
};
export const populateSelectedProductsFromInventory = productIds => (dispatch, getState) => {
  const {
    selectedProducts,
    selectedNotecards,
    selectedPackages
  } = categorizeProducts(productIds, getState);
  dispatch({
    type: SWAG_BUNDLES_POPULATE_PRODUCTS_FROM_INVENTORY,
    payload: {
      selectedProducts,
      selectedNotecards,
      selectedPackages
    }
  });
};